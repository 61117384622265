import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import StudyAbroadBanner from "../components/study-abroad/StudyAbroadBanner"
import WhatOffer from "../components/study-abroad/WhatOffer"
import About from "../components/study-abroad/About"

const StudyAbroadPage = () => {
  return (
    <Layout>
      <Seo title="Stack Learn | Study Abroad" description="The best study abroad program for students." keywords="study abroad, education, university, college, school" />
      <StudyAbroadBanner />
      <About />
      <WhatOffer />
    </Layout>
  )
}

export default StudyAbroadPage
